import React, { useEffect } from "react"
import About from "./About"
import Portifolio from "./Portfolio"
import ThirdSection from "../components/ThirdSection/ThirdSection"
import Hero from "../components/home/hero/Hero"
import AdvertCardListThree from "../components/AdvertCard/AdvertCard"
import VideoHome from "../components/VideoHome/VideoHome"
import AdvertCheapCards from "../components/AdvertCard/AdvertCheapCards"
import { useHistory } from "react-router-dom"
import useCheapProperties from "../hooks/useCheapProperties"
import { PuffLoader } from "react-spinners"
import Whatsapp1 from "../components/Whatsapp1/Whatsapp1"
import Call1 from "../components/Call1/Call1"

const Home = () => {

  const history = useHistory();

  
   

 
  return (
    <>

  
      <Hero />
      <Call1 />
      <Whatsapp1 />
      <Portifolio />
      <AdvertCardListThree />
     
     
      <About />
   

      <ThirdSection />
    
      
     
    </>
  )
}

export default Home
