import React from "react"
import img from "../images/pricing.jpg"
import Back from "../common/Back"
import "./contact.css"
import Map from "./Map"
import ContactInfo from "./ContactInfo"
import Form from "./Form"
import ThirdSection from "../ThirdSection/ThirdSection"
import GetInTouch from "../GetInTouch/GetInTouch"
import { Route } from "react-router-dom"
import Whatsapp1 from "../Whatsapp1/Whatsapp1"
import Call1 from "../Call1/Call1"

const Contact = () => {

  const ScrollToTop = () => {
    window.scrollTo(0, 0);
    return null;
  };
  return (
    <>
      <Route component={ScrollToTop} />

      <section className='contact mb'>
        <Back name='İletişim' title='Bize Ulaşın' cover={img} />
        <Whatsapp1 />
        <Call1 />
        <div className="pt-5 px-[3%] md:px-[6%]">
         <ThirdSection />
        {/*  <ContactInfo /> */}
        {/*  <Form /> */} 
       </div>
      </section>
    </>
  )
}

export default Contact
