import React from "react";
import { workExp } from "../../utils/data";
import css from "./Work.module.scss";
import { motion } from 'framer-motion'
import { draw, fadeIn, slideIn, staggerChildren, textVariant2, zoomIn } from "../../utils/motion";
import Iframe from "react-iframe";
import { FiPhone } from "react-icons/fi";
import { BiMap } from "react-icons/bi";


const ThirdSection = () => {
  return (
    <>
<div className="text-center">
<span className="primaryText">Adresimiz</span>

</div>


<motion.section 
      variants={staggerChildren}
      
      whileInView="show"
      viewport={{ once: false, amount: 0.25 }}
      className={`main-bg paddings ${css.wrapper}`}
       // Burada arka plan rengini mavi olarak ayarladık
    >
      <a className="anchor" id="work"></a>

      <div className={`innerWidth sm:pl-20  flexCenter ${css.container}`}>
        {/* heading */}
        
        <div className={`flexCenter ${css.experiences}`}>
         

              <motion.div variants={textVariant2}  className={`flexCenter ${css.exp}`}>
                
                <div className={css.post}>
                  <b>Bandırma Vatan Emlak</b>
                  <br />
                  <Iframe url="https://maps.google.com/maps?width=100%25&amp;height=500&amp;hl=tr&amp;q=G%C3%BCnayd%C4%B1n,%20Pazar%20Cd.%20No:44,%2010200%20Band%C4%B1rma/Bal%C4%B1kesir+(band%C4%B1rma%20vatan%20emlak)&amp;t=&amp;z=15&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
    
                   width="80%"
                   height="400"
                   margin="0"
                   padding="0"
                   id=""
                   className=""
                        
                /> 	
                
                </div>
                <div className={css.role}>
                <br />
                <b className="text-xl">Bandırma  Vatan Emlak<br /> Emlak Gayrimenkul  & Yatırım Danışmanlığı</b>
                 <br /> 
                
                <br /> 
                <div className="grid grid-cols-1 gap-6 py-8 sm:grid-cols-2 md:grid-cols-3">

                <div className="text-center">
                   <div className="icon-box !h-14 !w-14 !bg-primary text-white mx-auto text-2xl">
                     <BiMap />  
                  </div>
                  <h1 className="mt-2 text-lg font-semibold">Ofis Adresi</h1>
                  <p>Günaydın Mh. Pazar Cd. No:44</p>
                  <p>10200 Bandırma/Balıkesir</p>
                 </div>

                 <div className="text-center">
                   <div className="icon-box !h-14 !w-14 !bg-primary text-white mx-auto text-2xl">
                     <FiPhone />  
                  </div>
                  <h1 className="mt-2 text-lg font-semibold">Telefon</h1>
                    <p>İzzet BUDAK</p>

                    <p>0 (538) 509 57 96</p>
                    
                 </div>
                
                 </div>
                </div>

                
              </motion.div>

        


            
        


          <motion.div variants={zoomIn(1, 1)} className={css.progressbar}>
            <motion.div variants={fadeIn("down", "tween", 2, 1.5)} className={css.line}></motion.div>
            <div><div className={css.circle} style={{background: "#286F6C"}}></div></div>
            <div><div className={css.circle} style={{background: "#F2704E"}}></div></div>
            <div><div className={css.circle} style={{background: "#EEC048"}}></div></div>
          </motion.div>

        </div>
      </div>
    </motion.section>
    </>

  );
};

export default ThirdSection;
