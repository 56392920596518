import React, { useState } from "react";
import houses from "../../assets/city.jpg";
import HeroBtn from "./HeroBtn";
import { RiSearchLine } from "react-icons/ri";
import { PuffLoader } from "react-spinners";

const Hero = () => {
  const [filter, setFilter] = useState("");
  
  const [imageLoaded, setImageLoaded] = useState(false);

  // Call this function when the image is loaded
  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  const handleSearch = () => {
    // Redirect to Google with the search query without opening a new tab
    window.location.href = `https://bandirmavatanemlak.sahibinden.com/emlak?sorting=date_desc`;
  };

  const handleEnterKeyPress = (e) => {
    // Trigger handleSearch when Enter key is pressed
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  const handleInputFocus = () => {
    // Redirect to Google when the input field gains focus
    handleSearch();
  };

  const buttons = [
    { title: "TÜM İLANLAR", underline: "bg-[#FFFFFF]", to: "/adverts" },
    { title: "SATILIK", to: "/sale" },
    { title: "KİRALIK", to: "/rent" },
    { title: "KONUT", to: "/house" },
    { title: "ARSA", to: "/land" },
    { title: "İŞ YERİ", to: "/workplace" },
    { title: "TURİSTİK", to: "/touristy" },
  ];

  return (
    <>
      {!imageLoaded ? (
        <div className="h-[25rem] sm:h-[27rem] flex">
          <div className="absolute w-full">
            <div className="absolute w-full h-[25rem]"></div>
            <div className="wrapper flexCenter">
              <PuffLoader
                height="80"
                width="80"
                radius={1}
                color="#4066ff"
                aria-label="puff-loading"
              />
            </div>
            <img
              src={houses}
              className="object-cover w-full h-[25rem] sm:h-[27rem] hidden"
              onLoad={handleImageLoad}
            />
          </div>
        </div>
      ) : (
        <div className="h-[25rem] sm:h-[27rem] flex">
          <div className="absolute w-full">
            <div className="absolute w-full h-[25rem]"></div>
            <img
              src={houses}
              className="object-cover w-full h-[25rem] sm:h-[27rem]"
              onLoad={handleImageLoad}
            />
          </div>
          <div className="relative flex flex-col items-center w-full sm:pb-16 text-center">
            <p className="text-white px-2 text-[32px] sm:text-[57px] font-extrabold [text-shadow:_0_1px_0_rgb(0_0_0_/_40%)]">
              BANDIRMA VATAN EMLAK
            </p>
            <p className="text-white px-2 pt-2 sm:pt-0 sm:text-[23px]">
              "Bandırma Vatan Emlak - Güvenle Yatırım, Huzurla Yaşam!"
            </p>
            <ul className="">
              <div className="pt-8 flex text-center items-center">
                {buttons.map((button) => (
                  <HeroBtn
                    key={button.title}
                    to={button.to}
                    title={button.title}
                    underline={button.underline}
                  />
                ))}
              </div>
            </ul>
            {/* Input */}
            <div className="relative mt-9 mx-4 sm:mx-0">
              <input
                type="search"
                className="bg-white py-4 w-full sm:w-[37rem] rounded-full pl-5 placeholder:text-gray-500 placeholder:text-[11px] sm:placeholder:text-[14px] outline-0"
                placeholder="Adres, Şehir, Mahalle, İlan Ara.."
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
                onKeyDown={handleEnterKeyPress} // Calls handleEnterKeyPress on key down
                onFocus={handleInputFocus} // Redirects to Google on input focus
              />
              <div className="absolute w-[2.7rem] h-[2.7rem] rounded-full bg-[#2B2B2B] top-[0.4rem] right-1 flex items-center justify-center">
                <button onClick={handleSearch}>
                  <RiSearchLine className="text-white text-[22px]" />
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Hero;
