import React, { useEffect } from "react"
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import Footer from "../components/common/footer/Footer"
import About from "../components/about/About"
import Pricing from "../components/pricing/Pricing"
import Blog from "../components/blog/Blog"
import Services from "../components/services/Services"
import Contact from "../components/contact/Contact"
import Navbar from "../components/common/header/Navbar"
import Properties from "../components/properties/Properties"
import Header from "../components/Header/Header"
import Property from "../components/properties/Property"
import Home from "../pages/Home"
import Kiralik from "../pages/Kiralik/Kiralik"
import Satilik from "../pages/Satilik/Satilik"
import House from "../pages/House/House"
import Land from "../pages/Land/Land"
import Workplace from "../pages/WorkPlace/Workplace"
import Turistik from "../pages/Touristy/Touristy"
import NavbarYedek from "../components/Header/Headeryedek"
import Dropdown from "../components/Header/Dropdown"
import { useDispatch } from "react-redux"
import { closeDropdown } from "../redux/slice/uiSlice";
import BTeam from "../pages/Team/BTeam"
import MemberDetail from "../pages/MemberDetail/MemberDetail"
import CheapAdverts from "../pages/CheapAdverts/CheapAdverts"



const Pages = () => {
  

  const dispatch = useDispatch();

  const handleCloseDropdown = (e) => {
    dispatch(closeDropdown());
  };

  useEffect(() => {
   


    window.scrollTo(0, 0);
  
      

  }, [dispatch]);

  return (
    <>
     
      <Router>
     
      <NavbarYedek />
        <div
        className=" min-h-screen"
        onClick={handleCloseDropdown}
        onMouseOver={() => dispatch(closeDropdown())}
        >
      
        <Switch>
          <Route exact path='/' component={Home} />
          <Route exact path='/points' component={Contact} />
          <Route exact path='/about' component={About} />
          <Route exact path='/team/:id' component={BTeam} />
          <Route exact path='/member/:id' component={MemberDetail} />
          <Route exact path='/adverts' component={Properties} />
          <Route exact path='/cheapAdverts' component={CheapAdverts} />
          <Route exact path='/rent' component={Kiralik} />
          <Route exact path='/sale' component={Satilik} />
          <Route exact path='/house' component={House} />
          <Route exact path='/land' component={Land} />
          <Route exact path='/workplace' component={Workplace} />
          <Route exact path='/touristy' component={Turistik} />
          <Route path='/advert/:id' component={Property} />
          <Route exact path='/services' component={Services} />
          <Route exact path='/blog' component={Blog} />
          <Route exact path='/pricing' component={Pricing} />
          <Route exact path='/contact' component={Contact} />
        </Switch>
        
        <Footer />
        </div>
      </Router>
    
     
    </>
  )
}

export default Pages
