import { FaFacebook, FaInstagram, FaLinkedin, FaPhone, FaTwitter, FaWhatsapp, FaYoutube } from "react-icons/fa";
import { Link, Route, useParams } from "react-router-dom";
import Back from "../../components/common/Back";
import { teamMembers } from "../../data/dummyData";
import { motion } from 'framer-motion'
import { BiMap } from "react-icons/bi";
import { FiPhone } from "react-icons/fi";
import css from "./Work.module.scss";
import { fadeIn, staggerChildren, textVariant2, zoomIn } from "../../utils/motion";
import Iframe from "react-iframe";
import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from "react";
import { fetchPointDetail } from "../../redux/thunk/fetchPointDetail";
import Whatsapp1 from "../../components/Whatsapp1/Whatsapp1";
import Call1 from "../../components/Call1/Call1";


const Team = () => {


  const { id } = useParams(); // URL'den id parametresini alın
  const pointDetail = useSelector((state) => state.pointDetail);
  const dispatch = useDispatch();


  let baslik=  "";
  let img = "";
  let worker_img = "";
  let google_map = "";
  let adres_line1 = "";
  let adres_line2 = "";



  if (pointDetail) {
    baslik = pointDetail?.point_name;
    img =  pointDetail?.point_img;
    worker_img = pointDetail?.client_reviews;
    google_map = pointDetail?.map_link;
    adres_line1 = pointDetail?.adres_line1;
    adres_line2 = pointDetail?.adres_line2;

 
   }

  
    
   useEffect(() => {
   
    dispatch(fetchPointDetail(id));

    window.scrollTo(0, 0);
  
      

  }, [dispatch, id]);

    const ScrollToTop = () => {
      window.scrollTo(0, 0);
      return null;
    };


    return (
    <>
    <Route component={ScrollToTop} />
    {img && baslik ? <Back  name='Şubelerimiz' title={baslik} cover={img} /> : ""}
    <div className="pt-16 pb-10 px-[3%] md:px-[6%]  ">
    <Call1 />  
    <Whatsapp1 />


  

        <div className="text-center">
        <h1 className="mx-auto sub-heading">EKİBİMİZ</h1>
        <h1 className="heading">Ekibimizle Tanışın</h1>
      </div>
      <div className="grid grid-cols-1 gap-3 mt-8 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5">
      {Array.isArray(worker_img) && worker_img.map((item) => (
        <a href={`/member/${item.id}`} >
       <div className="h-[400px] w-full mb-20 relative group" key={item.id}>
       <img
         src={item.client_img}
         alt={item.client_title}
         className="object-cover w-full h-full rounded-lg transition-transform transform group-hover:scale-105"
       />
       
       <div className="absolute inset-0 flex items-center justify-center transition-opacity opacity-0 group-hover:opacity-100">
         
       </div>
       <div className="relative z-10 w-4/5 p-2 mx-auto -mt-10 text-center bg-white rounded-lg shadow-md dark:bg-dark-light">
           <h1 className="text-lg font-semibold">{item.client_title}</h1>
           <p>{item.client_description}</p>
        
           <div className="mt-2 flex-center-center gap-x-3  ">
          
            <a href={`tel:${item.phone_number}`}><FaPhone className="text-xl" /></a>
          
            </div>


         <div className="mt-3 flex-center-center gap-x-3   text-orange-500">
           {item.linkedin_link ? <a target="_blank" href={item.linkedin_link}>
                <div className="hover:text-primary transition-a ">
                <FaLinkedin className="text-xl"/>
              </div>
              </a> : "" }

            {item.instagram_link ? <a target="_blank" href={item.instagram_link}>
                <div className="hover:text-primary transition-a ">
                <FaInstagram className="text-xl"/>
              </div>
              </a> : "" }
             
              {item.facebook_link ? <a target="_blank" href={item.facebook_link}>
                <div className="hover:text-primary transition-a ">
                <FaFacebook className="text-xl"/>
              </div>
              </a> : ""}

              {item.youtube_link ? <a target="_blank" href={item.youtube_link}>
                <div className="hover:text-primary transition-a ">
                <FaYoutube className="text-xl"/>
              </div>
              </a> : "" }

              {item.whatsapp_link ? <a target="_blank" href={item.whatsapp_link}>
                <div className="hover:text-primary transition-a ">
                <FaWhatsapp className="text-xl"/>
              </div>
              </a> : "" }
             
             
           </div> 
           
         </div>
         
     </div>
        </a>
     
     ))}
     
      </div>
    </div>


<div className="text-center">
<span className="primaryText">Adresimiz</span>
</div>
    <motion.section 
      variants={staggerChildren}
      
      whileInView="show"
      viewport={{ once: false, amount: 0.25 }}
      className={`main-bg paddings ${css.wrapper}`}
       // Burada arka plan rengini mavi olarak ayarladık
    >
      <a className="anchor" id="work"></a>

      <div className={`innerWidth sm:pl-20 flexCenter ${css.container}`}>
        {/* heading */}

        <div className={`flexCenter ${css.experiences}`}>
         
             
              <motion.div variants={textVariant2}  className={`flexCenter ${css.exp}`}>
                <div className={css.post}>
                  {baslik ? <b>{baslik} Şubemiz</b> : ""}  
                  <br />
                  {google_map ? (
                 <Iframe
                   url={`https://maps.google.com/maps?width=520&height=400&hl=tr&q=${google_map}&t=&z=14&ie=UTF8&iwloc=B&output=embed`}
                   width="80%"
                   height="400"
                   margin="0"
                   padding="0"
                   id=""
                   className=""
                 />
               ) : ""}

                
                </div>
                <div className={css.role}>
                <br />
                {baslik ?  <b className="text-xl">{baslik}  Şubemiz<br /> Bandırma Vatan Emlak Gayrimenkul  & Yatırım Danışmanlığı</b> : "" }
                 <br /> 
                
                <br /> 
                <div className="grid grid-cols-1 gap-6 py-8 sm:grid-cols-2 md:grid-cols-3">

                <div className="text-center mb-10">
                   <div className="icon-box !h-14 !w-14 !bg-primary text-white mx-auto text-2xl">
                     <BiMap />  
                  </div>
                  <h1 className="mt-2 text-lg font-semibold ">Ofis Adresi</h1>
                  {adres_line1 ? <p> {adres_line1} </p> :  ""}
                  {adres_line2 ? <p> {adres_line2} </p> :  ""}
                 </div>

                 <div className="text-center mb-10">
                   <div className="icon-box !h-14 !w-14 !bg-primary text-white mx-auto text-2xl">
                     <FiPhone />  
                  </div>
                  <h1 className="mt-2 text-lg font-semibold">Telefon</h1>
                    <p>0 (538) 509 57 96</p>
                    
                 </div>
                
                 </div>
                </div>

                
              </motion.div>

             
            
        


          <motion.div variants={zoomIn(1, 1)} className={css.progressbar}>
            <motion.div variants={fadeIn("down", "tween", 2, 1.5)} className={css.line}></motion.div>
            <div><div className={css.circle} style={{background: "#286F6C"}}></div></div>
            <div><div className={css.circle} style={{background: "#F2704E"}}></div></div>
            <div><div className={css.circle} style={{background: "#EEC048"}}></div></div>
          </motion.div>

        </div>
      </div>
    </motion.section>
    </>
   
  );
};

export default Team;
