import React from 'react'
import whatsapp from "../images/whatsapp.png";


function Whatsapp1() {
    return (
        <div className='whatsapp-btn-container'>
        <a className="whatsapp-btn" href="https://api.whatsapp.com/send/?phone=905385095796&text&type=phone_number&app_absent=0" target="_blank">
           <img className="whatsapAboutImg" src={whatsapp} />  
        </a>  
      </div> 
    )
}

export default Whatsapp1