import React, { useEffect, useState } from "react";
import "./Properties.css";
import { PuffLoader } from "react-spinners";
import { Route, useHistory } from 'react-router-dom';
import HeroSearch from "../home/hero/HeroSearch";
import useEmlakjetProperties from "../../hooks/useEmlakjetProperties";
import SingleCheapAdvertCard from "../../pages/CheapAdverts/SingleCheapAdvertCard";
import Whatsapp1 from "../Whatsapp1/Whatsapp1";
import Call1 from "../Call1/Call1";
import AdvertCardListThree from "../AdvertCard/AdvertCard";


const Properties = () => {

  const history = useHistory();


  
  const { data, isError, isLoading } = useEmlakjetProperties();
  useEffect(() => {

    
   


    window.scrollTo(0, 0);
  
      

  }, []);

 
  const [filter, setFilter] = useState("");
  
  
  
  return (

    

    <div className="wrapper">
    <Call1 />
    <Whatsapp1 />
    <HeroSearch trade="House" filter={filter} setFilter={setFilter} />
    
    <AdvertCardListThree />
      </div>
  );
};

export default Properties;
