import React from 'react'
import whatsapp from "../images/call.png";


function Call1() {
    return (
        <div className='call-btn-container'>
        <a className="call-btn" href="tel:+90538-509-5796" target="_blank">
            <i className="fab fa-call"></i>
            <span>Bizimle İletişime Geçin</span>
           <img className="callAboutImg" src={whatsapp} />  
        </a>  
      </div> 
    )
}

export default Call1