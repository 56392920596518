/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { FiDelete, FiMoon, FiSun } from "react-icons/fi";
import { BiSearch, BiMenu, BiUser, BiBuildingHouse, BiPhoneCall } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import SingleLink from "./SingleLink";
import logo from "../images/Maya-Logopack12.png"
import "./navbar.css";
import { IoIosArrowDown } from "react-icons/io";

import {
  closeDropdown,
  closeSidebar,
  openSidebar,
  toggleDarkMode,
  uiStore,
} from "../../redux/slice/uiSlice";
import useLinks from "../../hooks/useLinks";



const NavbarYedek = () => {


  const { data, isError, isLoading } = useLinks();


  const [activeLinkId, setActiveLinkId] = useState(null);
  const [subeAc, setSubeAc] = useState(false);

const handleLinkClick = (linkId) => {
  setActiveLinkId((prevId) => (prevId === linkId ? null : linkId));
  dispatch(closeSidebar());
};
  const rootDoc = document.querySelector(":root");
  const { darkMode, isSidebarOpen } = useSelector(uiStore);
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const dispatch = useDispatch();
  
 
  // Dark mode toggle
  const handleDarkMode = () => {
    dispatch(toggleDarkMode());
  };

  const handleSube = () => {
    setSubeAc(!subeAc);

  }

  // Store darkmode value to localStorage;
  useEffect(() => {
    if (darkMode) rootDoc.classList.add("dark");
    else rootDoc.classList.remove("dark");
    localStorage.setItem("Martvilla-theme-mode", JSON.stringify(darkMode));
  }, [darkMode]);

  const handleClose = (e) => {
    if (!e.target.classList.contains("link")) {
      dispatch(closeDropdown());
    }
  };

  const handleCloseSidebar = (e) => {
    if (e.target.classList.contains("mobile-modal")) dispatch(closeSidebar());
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    /* navigate("/search"); */
  };

  return (
    <div className="pad-bot">
    <div
      className="navbar h-[70px] fixed w-full z-20 top-0 left-0 px-[2%]  md:px-[6%] flex-center-between py-[0.35rem] bg-white/80 border-b backdrop-blur-sm dark:border-dark dark:bg-card-dark/60 mb-10" 
      onMouseOver={handleClose}
    >
      <Link to="/" className="flex-shrink-0 flex-align-center gap-x-1">
        <h1 className="">  <div className="flex justify-center items-center h-[40px]">
       <img src={logo} alt="" className="w-[135px] h-auto mr-1" />
       
      </div></h1>
      </Link>

      <div className="flex-align-center gap-x-4">
        {/*-------------------------------------- Desktop Menu------------------------------------- */}
        <div className="hidden md:flex">

<SingleLink id="1" linkText="Anasayfa" url='/'   /> 
<SingleLink  id="2" linkText="İlanlarımız" url='/adverts'   /> 
<SingleLink id="3" linkText="Hakkımızda" url='/about'   /> 
<SingleLink   id="4" linkText="İletişim" url='/contact'   /> 

</div>





        {/*---------------------------------------- Mobile Menu------------------------------------- */}

        <div onClick={handleCloseSidebar}
          className={`lg:hidden mobile-modal fixed w-screen h-screen top-0 left-0 bg-black/50 z-50 opacity-0 pointer-events-none transition-a  ${
            isSidebarOpen && "open"
          }`}
         
        >
          
          <ul
            className={`mobile-dialog overflow-auto absolute flex flex-col space-y-4 p-3 bg-white dark:bg-card-dark h-screen max-w-[240px] w-full -translate-x-[500px] transition-a ${
              isSidebarOpen && "open"
            }`}
          >
           
           <div className="md:hidden fixed inset-y-0 left-0 w-60 p-4">
          <div className="flex justify-center items-center h-[70px]">
           <a href="/"><img src={logo} alt="" className="w-[120px] h-auto" /></a>
         </div>
     
     
         <ul className="space-y-1 text-lg text-center">
         {data && data.slice(0, 5).map((link) => (
            <div key={link.id}>
                {/* Ana link */}
                <div onClick={() => handleLinkClick(link.id)}>
                    <SingleLink {...link} />
                </div>
            </div>
        ))}

 
       

<SingleLink id="1" linkText="Anasayfa" url='/'   /> 
<SingleLink  id="2" linkText="İlanlarımız" url='/adverts'   /> 
<SingleLink id="3" linkText="Hakkımızda" url='/about'   /> 
<SingleLink   id="4" linkText="İletişim" url='/contact'   /> 
   
      <li>
        <a href="/contact"><button className="bg-[#DD251D] text-white text-base font-bold py-4 px-4 rounded">
          Görüşme Planlayın
        </button></a>
      </li>

      <li>
        <span>
          <a href="tel:+905385095796">+90 (538) 509 57 96</a>
        </span>
      </li>

      <li className="flex items-center justify-center">
        <a href="tel:+905385095796">
          <BiPhoneCall size={"40px"} />{" "}
        </a>
      </li>

      <li>
        <p className="text-sm">
          © 2024 Bandırma Vatan Emlak <br /> Tüm Hakları Saklıdır.
        </p>
      </li>
    </ul>
        </div>
          </ul>
        </div>

        <div className="space-x-2 flex-align-center">
          {/*----------------------------- search Bar----------------------------------------------------- */}
       {/*    <form onSubmit={handleSubmit}>
            <div
              className={`flex-align-center relative h-9 w-9 transition-a  border-slate-300 dark:border-dark rounded-full ${
                showSearchBar &&
                "!w-[150px] md:!w-[200px] border bg-transparent text-inherit"
              }`}
            >
              <input
                type="search"
                className={`outline-none border-none h-0 w-0 bg-transparent ${
                  showSearchBar && "!w-full !h-full px-4"
                }`}
                placeholder="İlan Ara..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <span
                className={`grid flex-shrink-0 rounded-full w-9 h-9 place-items-center text-white bg-dark sm:cursor-pointer ${
                  showSearchBar &&
                  " hover:bg-slate-700 text-inherit sm:cursor-pointer dark:hover:bg-hover-color-dark"
                }`}
                onClick={() => setShowSearchBar(!showSearchBar)}
              >
                <BiSearch className="text-muted " />
              </span>
            </div>
          </form> */}

   
       
   

          {/*----------------------------- Dark mode toggle-------------------------------------------------- */}
          <div
            className="grid flex-shrink-0 rounded-full w-9 h-9 place-items-center text-white bg-dark sm:cursor-pointer"
            onClick={handleDarkMode}
          >
            {darkMode ? <FiSun /> : <FiMoon />}
          </div>
          {/*----------------------------- Profile Icon-------------------------------------------------- */}
          <div className="bg-white shadow-md icon-box dark:bg-dark-light hover:shadow-lg hover:bg-transparent">
            <a href="tel:+905385095796">
              <BiPhoneCall  />
            </a>
          </div>
          <a href="/contact"><button className="hidden sm:flex bg-[#DD251D] text-white text-base font-bold py-2 px-2 ">
          Görüşme Planlayın
        </button></a>
          {/*------------------------------- Mobile Menu Toogle------------------------- */}
          <div
            className="icon-box md:hidden"
            onClick={() => dispatch(openSidebar())}
          >
            <BiMenu />
          </div>
        </div>
      </div>
    </div>
    </div>
  );
};

export default NavbarYedek;
