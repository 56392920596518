
import useProperties from "../../hooks/useProperties";

import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import SingleProductCard from "./SingleProductCard";
import { PuffLoader } from "react-spinners";
import SingleCheapAdvertCard from "../../pages/CheapAdverts/SingleCheapAdvertCard";
import useEmlakjetProperties from "../../hooks/useEmlakjetProperties";
import sahibinden from "../images/sahibinden.png"


const AdvertCardListThree = () => {

    const history = useHistory();



    
  return (

    <div className="px-[3%] md:px-[6%]">
    <div className="pt-10 pb-16">
      <div className="text-center">
        <a href="https://bandirmavatanemlak.sahibinden.com/emlak?sorting=date_desc">
          <h1 className="mx-auto sub-heading">TÜMÜNÜ GÖR</h1>
        </a>
        <h1 className="heading pb-10">Yeni İlanlarımız İçin Aşağıdaki Sahibinden Logosuna Tıklayınız</h1>
      </div>
  
      <div className="logo-container"> {/* Kırmızı çerçeve ve ortalama için kapsayıcı div */}
        <a href="https://bandirmavatanemlak.sahibinden.com/emlak?sorting=date_desc">
          <img src={sahibinden} alt="Sahibinden" className="logo-full-width" />
        </a>
      </div>
    </div>
  </div>
  
  
  
  );
};

export default AdvertCardListThree;
