/* eslint-disable jsx-a11y/anchor-is-valid */

import { BiBuildingHouse } from "react-icons/bi";
import { FaInstagram, FaLinkedin, FaTwitter,FaInstagramSquare, FaYoutube } from "react-icons/fa";
import { FiFacebook } from "react-icons/fi";
import { RiInstagramFill } from "react-icons/ri";
import { Link } from "react-router-dom";
import logo from "../../images/Maya-Logopack12.png"
import mayadan from "../../images/mayadan.png"
import emlakjet from "../../images/emlakjet_white.png"
import mg from "../../images/mg_white.png"




const Footer = () => {
  return (
    <div className="px-[2%] md:px-[6%] bg-card-dark border border-card-dark">
    <div className="mt-10">
    <div className="text-slate-200">
      <footer>
        <div className="flex flex-wrap gap-2">
          <div className="flex-1 basis-[10rem]">
          <div className="flex justify-center items-center h-[100px] sm:h-[180px]">
           <img src={logo} alt="" className="w-[270px] h-auto" />
         </div>

     
          </div>

        

          <div className="flex-1 basis-[10rem] text-center">
            <h2 className="text-xl font-semibold">Hızlı Bağlantı</h2>
            <ul>
            <li className="my-3 text-muted">
                <a href="/">Anasayfa</a>
              </li>
              <li className="my-3 text-muted">
                <a href="/about"> Hakkımızda</a>
              </li>
              <li className="my-3 text-muted">
                <a href="/adverts">İlanlarımız</a>
              </li>
              
              <li className="my-3 text-muted">
                <a href="/contact">İletişim</a>
              </li>
            </ul>
          </div>

        

          <div className="flex-1 basis-[10rem] text-center">
            <h2 className="text-xl font-semibold">İletişim</h2>
            <ul>
            <li className="my-3 text-muted">
                <p><b>Telefon:</b> 0 (538) 509 57 96</p>
              </li>
              <li className="my-3 text-muted">
              <p><b>Adres: </b> 
              Günaydın Mh. Pazar Cd. No:44, 10200 Bandırma/Balıkesir</p>
              </li>
             
            
            </ul>
          </div>
        </div>
      </footer>
    <div className="py-2 mt-3 text-center border-t text-muted border-dark col">
     <div className="flex justify-between">
       <a href="#"  > </a>
       <p className="text-xs sm:text-base">
         BANDIRMA VATAN EMLAK | Tüm Hakları Saklıdır © 2024
       </p>
       <a href="#"></a>
     </div>
   </div>


    </div>
    </ div>

    </div>
  );
};

export default Footer;
